// The soundToSpellingMap and ipaToSoundStringMap can be generated using a helper script on the server.
// Located at: /scripts/speech-recognition/create-spelling-to-sound-map.js

// The original sound-to-letter mappings are in a file on box: 'Letters and databases.xlsx'
// Anytime the mappings in this file changes, we should do the following:
// 1. export out a new CSV file from the XLSX (update MAPPING_DATABASE to point to this file if different filename)
// 2. run the helper script on the server (node scripts/speech-recognition/create-spelling-to-sound-map)
// 3. copy the outputs for soundToSpellingMap and ipaToSoundStringMap to the proper places in the client file (orthographic-mapping-helpers.js)

// Mapping of sounds to letters
const soundToSpellingMap = {
	1: ['ay', 'a', 'ai'],
	2: ['i', 'y', 'ie'],
	3: ['o', 'oa', 'oe', 'ough', 'ow'],
	4: ['ow', 'ou'],
	5: ['oy', 'oi'],
	b: ['b', 'bb'],
	ʧ: ['ch', 'c'],
	d: ['d', 'dd', 't'],
	f: ['f', 'ff', 'ph', 'gh'],
	g: ['g', 'gg', 'x'],
	ɡ: ['g', 'gg', 'x'],
	ʒ: ['s'],
	h: ['h'],
	ʤ: ['j', 'g', 'dg', 'd'],
	k: ['k', 'c', 'ck', 'q', 'x', 'ch'],
	l: ['l', 'll'],
	m: ['m', 'mm'],
	n: ['n', 'nn', 'kn', 'gn'],
	ŋ: ['ng', 'n'],
	p: ['p', 'pp'],
	r: ['r', 'rr', 'wr'],
	s: ['s', 'c', 'ss', 'ps', 'ts'],
	ʃ: ['sh', 't', 's', 'c', 'ch', 'sc'],
	t: ['t', 'tt', 'pt'],
	θ: ['th'],
	ð: ['th'],
	v: ['v'],
	w: ['w', 'wh', 'u'],
	j: ['y', 'u'],
	z: ['z', 's', 'zz', 'x'],
	æ: ['a'],
	ɔ: ['o', 'a', 'au', 'aw', 'ou', 'oo', 'oa', 'augh'],
	ɛ: ['e', 'ai', 'ea'],
	i: ['ey', 'i', 'y', 'e', 'ee', 'ea'],
	ə: ['a', 'e', 'i', 'o', 'u', 'io', 'y', 'ou'],
	ɪ: ['i', 'e', 'y', 'a'],
	ɑ: ['o', 'a', 'aw'],
	ʊ: ['oo', 'u'],
	ʌ: ['u', 'ou'],
	u: ['u', 'oo', 'ue', 'ew'],
	ɝ: ['a', 'e', 'i', 'o', 'u']
};

// Mapping for two-character IPA sounds into a soundString. This can also be generated
// from the above script.
const ipaToSoundStringMap = {
	dʒ: 'ʤ',
	tʃ: 'ʧ',
	eɪ: '1',
	aɪ: '2',
	oʊ: '3',
	aʊ: '4',
	ɔɪ: '5'
};

// Function to swap the keys and values in an array
const swapKeysWithValues = (obj) => {
	const returnValue = {};
	for (const key in obj) {
		returnValue[obj[key]] = key;
	}
	return returnValue;
};

// This is needed to go from soundstring back to IPAs.
const soundStringToIpaMap = swapKeysWithValues(ipaToSoundStringMap);

// The Microsoft speech recognition returns us weird IPAs for some words. This object holds
// the exception words where we need to replace the generated IPAs with our own IPAs.
const replacementIpaForWords = {
	card: 'kɑrd',
	honour: 'ɑ-nər'
};

export { soundToSpellingMap, ipaToSoundStringMap, soundStringToIpaMap, replacementIpaForWords };
