import { defineAsyncComponent, markRaw } from 'vue';
import { defineStore, mapState } from 'pinia';
import { has } from 'lodash';
import { getServiceStore } from '@/plugins/FeathersAPI';

const Logout = defineAsyncComponent(() => import('./components/Logout.vue'));

const isAnonymousMiddleware = ({ next }) => {
	if (getServiceStore('auth').isAuthenticated) {
		const user = getServiceStore('auth').user;
		let loginSucessRoute = usePluginStore().getRoute('loginSuccess');
		if (typeof loginSucessRoute == 'function') loginSucessRoute = loginSucessRoute({ user });
		return next({
			path: loginSucessRoute
		});
	}

	return next();
};

let useStore;
const usePluginStore = () => {
	if (typeof useStore !== 'function') throw new Error(PLUGIN_NAME + ' Store not setup');
	return useStore();
};

// Authentication Plugin
const PLUGIN_NAME = 'AuthenticationPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {
	logo: undefined,
	loginRoute: '/login',
	loginTemplate: false,
	loginFields: [
		{
			name: 'email',
			type: 'TextInput',
			label: 'Email',
			validators: ['isEmail'],
			placeholder: 'Email...',
			required: true,
			props: {
				type: 'email'
			}
		},
		{
			name: 'password',
			type: 'PasswordInput',
			label: 'Password',
			placeholder: 'Password...',
			required: true
		}
	],
	loginNoAccountTextComponent: markRaw({
		template: `<span>Don't have an account?<br /><router-link :to="getRoute('createAccount')" style="text-decoration: underline;">Create one</router-link> or <a href="https://tactustherapy.com/rehab" style="text-decoration: underline;">learn more</a></span>`,
		computed: {
			...mapState(usePluginStore, ['getRoute'])
		}
	}),
	reAuthFields: [
		{
			name: 'password',
			type: 'PasswordInput',
			label: 'Password',
			placeholder: 'Password...',
			required: true
		}
	],
	loginSuccessRoute: '/', // could also be a function receiving an 'auth' object as the param and returning a string path
	createAccountRoute: '/create-account',
	createAccountTemplate: false,
	createAccountFields: [
		{
			name: 'name',
			type: 'TextInput',
			label: 'Name',
			required: true
		},
		{
			name: 'email',
			type: 'TextInput',
			label: 'Email Address',
			validators: ['isEmail'],
			required: true,
			props: {
				type: 'email'
			}
		}
	],
	loginOnActivation: true,
	forgotPasswordRoute: '/forgot-password',
	forgotPasswordTemplate: false,
	forgotPasswordFields: [
		{
			name: 'email',
			type: 'TextInput',
			label: 'Email Address',
			validators: ['isEmail'],
			required: true,
			props: {
				type: 'email'
			}
		}
	],
	resetPasswordRoute: '/reset-password',
	resetPasswordTemplate: false,
	resetPasswordFields: [
		{
			name: 'password',
			type: 'PasswordInput',
			label: 'Password',
			validators: ['isStrongPassword'],
			required: true,
			instructions: 'Passwords must be at least 12 characters'
		},
		{
			name: 'confirm_password',
			type: 'PasswordInput',
			label: 'Confirm Password',
			validators: ['isMatch:password'],
			required: true
		}
	],
	activateAccountRoute: '/activate-account',
	activateAccountTemplate: false,
	activateAccountFields: [
		{
			name: 'password',
			type: 'PasswordInput',
			label: 'Password',
			validators: ['isStrongPassword'],
			required: true
		},
		{
			name: 'confirm_password',
			type: 'PasswordInput',
			label: 'Confirm Password',
			validators: ['isMatch:password'],
			required: true
		}
	],
	emailVerificationRoute: '/email-verification',
	emailVerificationTemplate: false,
	baseTemplate: defineAsyncComponent(() => import('./components/AuthPage.vue'))
};

const AuthPlugin = {
	install(app, userOptions = {}) {
		// check for dependencies
		if (app.config.globalProperties.$plugins && !app.config.globalProperties.$plugins['FormPlugin']) {
			console.error(`${PLUGIN_NAME}:${VERSION} - requires FormPlugin to be installed`);
		}
		if (typeof userOptions.router == 'undefined') {
			console.error(`${PLUGIN_NAME}:${VERSION} - options requires a 'router' property`);
		}
		if (!(typeof userOptions.router == 'object' && Object.hasOwnProperty.call(userOptions.router, 'addRoute'))) {
			console.error(`${PLUGIN_NAME}:${VERSION} - options 'router' property must reference a VueRouter`);
		}

		const options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (!has(app.config.globalProperties, '$plugins')) {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		const routes = {
			login: options.loginRoute ? options.loginRoute : undefined,
			loginSuccess: options.loginSuccessRoute ? options.loginSuccessRoute : undefined,
			createAccount: options.createAccountRoute ? options.createAccountRoute : undefined,
			forgotPassword: options.forgotPasswordRoute ? options.forgotPasswordRoute : undefined,
			resetPassword: options.resetPasswordRoute ? options.resetPasswordRoute : undefined,
			activateAccount: options.activateAccountRoute ? options.activateAccountRoute : undefined,
			emailVerification: options.emailVerificationRoute ? options.emailVerificationRoute : undefined
		};
		const formFields = {
			login: options.loginFields ? options.loginFields : {},
			reAuth: options.reAuthFields ? options.reAuthFields : {},
			createAccount: options.createAccountFields ? options.createAccountFields : {},
			forgotPassword: options.forgotPasswordFields ? options.forgotPasswordFields : {},
			resetPassword: options.resetPasswordFields ? options.resetPasswordFields : {},
			activateAccount: options.activateAccountFields ? options.activateAccountFields : {}
		};

		useStore = defineStore(PLUGIN_NAME, {
			state: () => {
				return {
					routes,
					formFields,
					loginOnActivation: options.loginOnActivation,
					logo: options.logo,
					LoginNoAccountTextComponent: options.loginNoAccountTextComponent,
					loginRedirectRoute: undefined
				};
			},
			getters: {
				getRoute(state) {
					return (route, params = {}) => {
						let path = state.routes[route];
						if (typeof path == 'function') return path;

						// assign values to optional route params
						// ':([^\/:]+)\?$' has been validated with https://devina.io/redos-checker
						// this use of RegExp is -- SAFE --
						const optionalParams = new RegExp(':([^\\/:]+)\\?$', 'gm');
						const match = optionalParams.exec(path);
						if (match) path = path.replace(match[0], params[match[1]] || '');

						// assign values to required params
						// ':([^\/:]+)' has been validated with https://devina.io/redos-checker
						// this use of RegExp is -- SAFE --
						const otherParams = new RegExp(':([^\\/:]+)', 'gm');
						let m;
						while ((m = otherParams.exec(path)) !== null) {
							if (!params[m[1]]) {
								console.error('"' + route + '" route is missing required "' + m[1] + '" param.');
								return undefined;
							}
							path.replace(m[0], params[m[1]]);
						}
						return path;
					};
				},
				getFormFields(state) {
					return (route) => {
						return state.formFields[route] || undefined;
					};
				}
			}
		});

		if (options.loginRoute) {
			if (options.loginRoute != '/login') {
				userOptions.router.addRoute({
					path: '/login',
					redirect: options.loginRoute
				});
			}
			userOptions.router.addRoute({
				path: options.loginRoute,
				name: 'Login',
				component: () => import('./views/Login.vue'),
				props: {
					baseTemplate: options.loginTemplate ? options.loginTemplate : options.baseTemplate
				},
				meta: {
					middleware: [isAnonymousMiddleware]
				}
			});
		}
		if (options.createAccountRoute) {
			userOptions.router.addRoute({
				path: options.createAccountRoute,
				name: 'CreateAccount',
				component: () => import('./views/CreateAccount.vue'),
				props: {
					baseTemplate: options.createAccountTemplate ? options.createAccountTemplate : options.baseTemplate
				},
				meta: {
					middleware: [isAnonymousMiddleware]
				}
			});
		}
		if (options.forgotPasswordRoute) {
			userOptions.router.addRoute({
				path: options.forgotPasswordRoute,
				name: 'ForgotPassword',
				component: () => import('./views/ForgotPassword.vue'),
				props: {
					baseTemplate: options.forgotPasswordTemplate ? options.forgotPasswordTemplate : options.baseTemplate
				},
				meta: {
					middleware: [isAnonymousMiddleware]
				}
			});
		}
		if (options.resetPasswordRoute) {
			userOptions.router.addRoute({
				path: options.resetPasswordRoute,
				name: 'ResetPassword',
				component: () => import('./views/ResetPassword.vue'),
				props: {
					baseTemplate: options.resetPasswordTemplate ? options.resetPasswordTemplate : options.baseTemplate
				},
				meta: {
					middleware: [isAnonymousMiddleware]
				}
			});
		}
		if (options.activateAccountRoute) {
			userOptions.router.addRoute({
				path: options.activateAccountRoute,
				name: 'ActivateAccount',
				component: () => import('./views/ActivateAccount.vue'),
				props: {
					baseTemplate: options.activateAccountTemplate ? options.activateAccountTemplate : options.baseTemplate
				},
				meta: {
					middleware: [isAnonymousMiddleware]
				}
			});
		}
		if (options.emailVerificationRoute) {
			userOptions.router.addRoute({
				path: options.emailVerificationRoute,
				name: 'EmailVerification',
				component: () => import('./views/EmailVerification.vue'),
				props: {
					baseTemplate: options.emailVerificationTemplate ? options.emailVerificationTemplate : options.baseTemplate
				}
			});
		}
	}
};

export { AuthPlugin as default, usePluginStore, Logout, isAnonymousMiddleware };
