//import { createApp } from 'vue';
//import eventBus from './eventBus';
//import DebugConsole from './components/DebugConsole.vue';

// Debug Plugin
const PLUGIN_NAME = 'DebugPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {};

const DebugPlugin = {
	install(app, userOptions) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (!Object.hasOwnProperty.call(app.config.globalProperties, '$plugins')) {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		// Create the plugin's root Vue instance
		//const manager = createApp(DebugConsole);

		// app.directive('debug', {
		// 	beforeMount(el, binding) {
		// 		eventBus.emit('add', { name: binding.arg, value: binding.value, element: el });
		// 	},
		// 	unmounted(el, binding) {
		// 		eventBus.emit('remove', binding.value);
		// 	}
		// });

		// Mount the plugin's root Vue instance on a new div element added to body
		//manager.mount(document.body.appendChild(document.createElement('div')));

		// array of console properties [log, warn, error, ...]
		const consoleProps = Object.keys(console);
		// create an array to store a list of all calls to a console funciton
		console.logTrace = [];
		// loop through console properties
		consoleProps.forEach((prop) => {
			if (typeof console[prop] == 'function') {
				// rename the original functionality
				console[prop + 'STD'] = console[prop].bind(console);
				// define new funciton to add functionality to push the called funciton on to the logTrace array
				console[prop] = function (...args) {
					console.logTrace.push({
						fn: prop,
						args: prop == 'error' ? Array.from(args).map((arg) => ({ message: arg.message })) : Array.from(args),
						time: new Date().getTime()
					});
					// still call the original function
					console[prop + 'STD'](...args);
				};
			}
		});
	}
};
export { DebugPlugin as default };
