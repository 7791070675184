import { ref, h, render } from 'vue';
import mitt from 'mitt';
import MediaManager from './components/MediaManager.vue';
import AudioComponent from './components/Audio.vue';
import VideoComponent from './components/Video.vue';

const PLUGIN_NAME = 'MediaPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {};

const MediaPlugin = {
	install(app, userOptions) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (typeof app.config.globalProperties.$plugins == 'undefined') {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		// Create the plugin's root Vue instance
		const manager = h(MediaManager);
		manager.appContext = app._context;

		// register 'Audio' component globally
		app.component('Audio', AudioComponent);
		// register 'Video' component globally
		app.component('Video', VideoComponent);

		const eventBus = mitt();
		const userInteracted = ref(false);
		const audio = new Audio();

		const mediaObject = {
			play(name, options) {
				if (name) eventBus.emit('play', { name, options });
				else eventBus.emit('playQueue');
			},
			pause(name) {
				eventBus.emit('pause', { name });
			},
			stop(name) {
				eventBus.emit('stop', { name });
			},
			stopMedia() {
				eventBus.emit('stopMedia');
			},
			addToQueue(name, options) {
				eventBus.emit('addToQueue', { name, options });
			},
			removeFromQueue(name) {
				eventBus.emit('removeFromQueue', { name });
			},
			manager: eventBus
		};
		Object.defineProperty(mediaObject, 'hasUserInteracted', {
			get: () => {
				return userInteracted.value;
			},
			set: (val) => {
				userInteracted.value = !!val;
			}
		});

		const mediaAudioObject = {
			get(prop) {
				return prop ? audio[prop] : audio;
			},
			set(prop, val) {
				audio[prop] = val;
			}
		};

		app.provide('$media', mediaObject);
		app.provide('$mediaAudio', mediaAudioObject);

		// Mount the plugin's root Vue instance on a new div element added to body
		const el = document.createElement('div');
		el.setAttribute('id', 'media_manager');
		render(manager, document.body.appendChild(el));
	}
};
export { MediaPlugin as default };
