<script>
import iconDefs from '@/assets/icons/icon-defs.svg';
import BaseIcon from './BaseIcon.vue';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Icon',
	components: {
		BaseIcon
	},
	props: {
		icon: {
			type: String,
			required: true
		},
		animate: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			iconDefs,
			animation: undefined,
			animationInterval: undefined,
			activeAnimationFrame: 0,
			animationsList: {
				speaker: {
					frames: ['speaker-animation-2', 'speaker-animation-3', 'speaker-animation-4', 'speaker-animation-1'],
					speed: 4
				}
			},
			baseIntervalDuration: 500
		};
	},
	computed: {
		iconName() {
			return this.animation ? this.animationFrames[this.activeAnimationFrame] : this.icon;
		},
		animationNames() {
			return Object.keys(this.animationsList);
		},
		animationFrames() {
			return this.animationsList[this.animation].frames;
		}
	},
	watch: {
		animate(val) {
			if (val && this.animation) this.startAnimation();
			else this.stopAnimation();
		},
		icon() {
			this.setAnimation();
			if (this.animate && this.animation) this.startAnimation();
		}
	},
	created() {
		this.setAnimation();
		if (this.animate && this.animation) this.startAnimation();
	},
	methods: {
		startAnimation() {
			this.animationInterval = setInterval(() => {
				this.activeAnimationFrame =
					this.activeAnimationFrame + 1 < this.animationsList[this.animation].frames.length
						? this.activeAnimationFrame + 1
						: 0;
			}, this.baseIntervalDuration - (this.baseIntervalDuration / 5) * (this.animationsList[this.animation].speed - 1));
		},
		stopAnimation() {
			this.activeAnimationFrame = 0;
			clearInterval(this.animationInterval);
		},
		setAnimation() {
			if (this.icon.substring(this.icon.length - 10, this.icon.length) !== '-animation') {
				this.animation = undefined;
				this.stopAnimation();
				return;
			}

			const animation = this.icon.substring(0, this.icon.length - 10);
			if (this.animationNames.includes(animation)) {
				this.animation = animation;
			}
		}
	}
};
</script>

<template>
	<BaseIcon :icon="iconName" :defs="iconDefs" />
</template>

<style lang="scss">
.icon-first-letter,
.icon-final-sound,
.icon-rhyme,
.icon-same-start,
.icon-starting-sound,
.icon-first-sound,
.icon-syllable,
.icon-written-word {
	.size-placeholder {
		height: 200%;
	}
}
</style>
