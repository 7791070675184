import {
	soundToSpellingMap,
	ipaToSoundStringMap,
	soundStringToIpaMap,
	replacementIpaForWords
} from './orthographic-mapping-helpers';

/**
 * getOrthographicMapping is a function that figures out how to map the IPA sounds to the letters in
 * the word.
 *
 * A document that details the need, concept, and outline for this algorithm:
 * https://tactustherapy.box.com/s/olte506h0uta4hzn3a6v4ge97rqwxooi
 *
 * @param {string} rawWord - The word we are mapping (e.g. 'airplane', 'x-ray', 'TV')
 * @param {string} rawSounds - The sounds as a string of IPAs, syllables separated by a dash (-).
 * @returns {Object[]} - an array of objects where each object represents a mapping between sound and letter.
 * we call this a mapping array.
 */
const getOrthographicMapping = (rawWord, rawSounds) => {
	// in some cases, microsoft gives strange IPAs, so if those cases come up, replace IPA with our own
	if (rawWord in replacementIpaForWords) {
		rawSounds = replacementIpaForWords[rawWord];
	}

	// deal with hyphens in the word, lowercase the letters
	const convertedWord = rawWord.split('-').join('').toLowerCase();

	// all sounds converted to single characters
	const convertedSounds = processSoundStrings(rawSounds);

	let result = [];
	if (rawWord === null || rawWord.length === 0) return [];

	/**
	 * This is the backtacking function that maps the sounds to letters.
	 * @param {string} word - The word we are mapping, lowercased and without hyphens (e.g. 'airplane', 'xray', 'tv')
	 * @param {string} sounds - The IPA sounds for the given word
	 * @param {number} currLetterIndex - an index telling us where we are presently looking in the word
	 * @param {number} numSoundsMapped - number of sounds mapped so far
	 * @param {number} numSoundsSkipped - number of sounds that have been skipped so far (couldn't find letters that match to it)
	 * @param {Object[]} initialMapping - mapping array as we do our backtracking algorithm
	 * @param {Object[]} finalMapping - the final mapping array used for handling unmapped sounds, unmapped letters, and special cases
	 * @param {number} soundIndex - which sound we are presently considering
	 * @param {String[]} initialUnmappedLetters - keep track of what letters are unmapped so far. used to figure out finalMapping later
	 * @param {String[]} initialUnmappedSounds - keep track of what sounds are unmapped so far. used to figure out finalMapping later
	 *
	 * @returns {Object[]} - an array of objects where each object represents a mapping between sound and letter.
	 * we call this a mapping array.
	 */
	const backtrack = (params) => {
		const {
			word,
			sounds,
			currLetterIndex,
			numSoundsMapped,
			numSoundsSkipped,
			initialMapping,
			finalMapping,
			soundIndex,
			initialUnmappedLetters,
			initialUnmappedSounds
		} = params;

		// we are done with this path of mappings when we reach the end of a word or have used up all sounds
		if (currLetterIndex >= word.length || soundIndex >= sounds.length) {
			// OPTIONAL: to optimize this algorithm, we can return early if we have skipped more sounds than
			// the current answer has skipped

			// console.log('we finished with mapping', initialMapping, 'and numSoundsMapped', numSoundsMapped);
			const numLettersMapped = initialMapping.reduce((total, obj) => total + obj.spelling.length, 0);
			if (
				result.length === 0 ||
				result.numSoundsMapped < numSoundsMapped ||
				(result.numSoundsMapped == numSoundsMapped && result.numLettersMapped < numLettersMapped)
			) {
				const soundsAtEnd = [];
				if (soundIndex < sounds.length) {
					// we have a sound that is unmapped at the end of the word
					for (let i = soundIndex; i < sounds.length; i++) {
						soundsAtEnd.push({
							sound: sounds[i],
							soundIndex: i
						});
					}
				}

				const lettersAtEnd = [];
				if (currLetterIndex < word.length) {
					// we have letters unmapped at end of the word
					for (let i = currLetterIndex; i < word.length; i++) {
						lettersAtEnd.push({ spelling: word[i], index: i });
					}
				}

				// only store the "best" answer
				result = {
					initialMapping: [...initialMapping],
					finalMapping: [...finalMapping],
					numSoundsMapped,
					numLettersMapped,
					numSoundsSkipped,
					initialUnmappedLetters: [...initialUnmappedLetters, ...lettersAtEnd],
					initialUnmappedSounds: [...initialUnmappedSounds, ...soundsAtEnd]
				};
			}
			// if we want to keep track of all possible answers, we can push each solution into an array
			//result.push({ ...mapping, numSoundsMapped });
			return;
		}
		const sound = sounds[soundIndex];
		const possibleSpellings = soundToSpellingMap[sound]; // an array of possible letter spellings that sound maps to
		let found = false;
		if (!possibleSpellings) {
			// this sound has no mapping in our table. not a problem
		} else {
			// console.log('this sound', sound, ' at index', soundIndex, 'maps to', spelling.join(','));
			for (const spelling of possibleSpellings) {
				const comparison = word.substring(currLetterIndex, currLetterIndex + spelling.length);

				// we have found the letter in the word at the currLetterIndex
				if (spelling === comparison) {
					found = true;
					const startLetterIndex = currLetterIndex;
					const endLetterIndex = currLetterIndex + spelling.length - 1;

					// push the mapping
					initialMapping.push({
						spelling,
						sound: decodeSoundString(sound),
						startLetterIndex, // index where the letter starts(s) in the word
						endLetterIndex, // index where the letter(s) end in the word (if just single letter, the startLetterIndex and endLetterIndex are the same)
						soundIndex // used later to help figure out boundaries of unmapped sounds
					});

					// proceed with next mapping
					backtrack({
						word,
						sounds,
						currLetterIndex: currLetterIndex + spelling.length,
						numSoundsMapped: numSoundsMapped + 1,
						numSoundsSkipped,
						initialMapping,
						finalMapping,
						soundIndex: soundIndex + 1,
						initialUnmappedLetters,
						initialUnmappedSounds
					});
					// undo the mapping so we can try next possibility
					initialMapping.pop();
				}
			}
		}

		// we did not find a match for the sound above. we do two recursive calls here
		if (!found) {
			// First: skip a letter (ie, keep track of unmapped letter).
			initialUnmappedLetters.push({ spelling: word[currLetterIndex], index: currLetterIndex });
			backtrack({
				word,
				sounds,
				currLetterIndex: currLetterIndex + 1,
				numSoundsMapped,
				numSoundsSkipped: numSoundsSkipped + 1, // we didn't match anything above
				initialMapping,
				finalMapping,
				soundIndex,
				initialUnmappedLetters,
				initialUnmappedSounds
			});
			initialUnmappedLetters.pop();

			// Second: skip this sound.
			initialUnmappedSounds.push({
				sound: sounds[soundIndex],
				soundIndex
			});
			backtrack({
				word,
				sounds,
				currLetterIndex,
				numSoundsMapped,
				numSoundsSkipped: numSoundsSkipped + 1, // we didn't match anything above
				initialMapping,
				finalMapping,
				soundIndex: soundIndex + 1,
				initialUnmappedLetters,
				initialUnmappedSounds
			});
			initialUnmappedSounds.pop();
		}
	};

	// be sure to use the converted sounds (single characters to represent sounds)
	backtrack({
		word: convertedWord,
		sounds: convertedSounds,
		currLetterIndex: 0,
		numSoundsMapped: 0,
		numSoundsSkipped: 0,
		initialMapping: [],
		finalMapping: [],
		soundIndex: 0,
		initialUnmappedLetters: [],
		initialUnmappedSounds: []
	});

	// console.log('BACKTRACK RESULTS', result);
	// console.log('initial mapping:', JSON.stringify(result.initialMapping, null, 5));
	// console.log('initial unmapped letters', JSON.stringify(result.initialUnmappedLetters, null, 5));
	// console.log('initial unmapped sounds', JSON.stringify(result.initialUnmappedSounds, null, 5));

	// now create final mapping as we figure out what maps to what using initialUnmappedLetters and initialUnmappedSounds
	const { finalMapping } = createFinalMapping(
		rawWord,
		convertedSounds.join(''),
		result.initialMapping,
		result.initialUnmappedLetters,
		result.initialUnmappedSounds
	);
	// console.log('final mapping:', finalMapping);
	// console.log('final unmapped letters', finalUnmappedLetters);
	// console.log('final unmapped sounds', finalUnmappedSounds);

	return finalMapping;

	// return {
	// 	word: rawWord,
	// 	sounds: rawSounds,
	// 	initialMapping: result.initialMapping.map((obj) => obj.spelling + ' : ' + obj.sound).join('\n'),
	// 	initialUnmappedLetters: result.initialUnmappedLetters.map((item) => item.spelling).join(', '),
	// 	initialUnmappedSounds: result.initialUnmappedSounds.map((item) => item.sound).join(', '),
	// 	finalMapping: finalMapping.map((obj) => obj.spelling + ' : ' + obj.sound).join('\n'),
	// 	finalUnmappedLetters: finalUnmappedLetters.map((item) => item.spelling).join(', '),
	// 	finalUnmappedSounds: finalUnmappedSounds.map((item) => item.sound).join(', ')
	// };
};

//----------------------------------------------------------------------------------------------------------
// create the final mapping array
//----------------------------------------------------------------------------------------------------------
const createFinalMapping = (rawWord, sounds, initialMapping, initialUnmappedLetters, initialUnmappedSounds) => {
	const word = rawWord.toLowerCase();

	let finalMapping = [];
	const finalUnmappedLetters = [...initialUnmappedLetters];
	const finalUnmappedSounds = [...initialUnmappedSounds];

	// handle any unmapped sounds by trying to match them to letters that are unmapped
	const newMappingObjects = createMappingsForUnmappedSoundsAndLetters(
		word,
		sounds,
		initialMapping,
		initialUnmappedSounds,
		finalUnmappedLetters,
		finalUnmappedSounds
	);
	// create the final mapping array by merging any new mapping objects for unmapped by aligned sounds+letters
	finalMapping = mergeMappingArrays(initialMapping, newMappingObjects);

	// handle any remaining unmapped letters now
	handleUnmappedLetters(finalUnmappedLetters, finalMapping);

	// handle special cases if they are applicable
	handleSpecialCase(word, sounds, finalMapping, finalUnmappedSounds, 'l', 'ə');
	handleSpecialCase(word, sounds, finalMapping, finalUnmappedSounds, 'r', 'ə');
	handleSpecialCase(word, sounds, finalMapping, finalUnmappedSounds, 'r', 'ɛ');

	// join any remaining unmapped sounds so that all sounds are mapped
	handleRemainingUnmappedSounds(sounds, finalMapping, finalUnmappedSounds);

	// use the original spelling in the word (since we lowercase for our algorithm)
	restoreUsingRawLetters(rawWord, finalMapping);

	// console.log(
	// 	'createFinalMapping RESULT:',
	// 	'finalMapping',
	// 	JSON.stringify(finalMapping, null, 5),
	// 	'finalUnmappedLetters',
	// 	finalUnmappedLetters,
	// 	'finalUnmappedSounds',
	// 	finalUnmappedSounds
	// );
	return { finalMapping, finalUnmappedLetters, finalUnmappedSounds };
};

//----------------------------------------------------------------------------------------------------------
// handle unmapped letters
//----------------------------------------------------------------------------------------------------------

const handleUnmappedLetters = (finalUnmappedLetters, finalMapping) => {
	// console.log(
	// 	'handleUnmappedLetters INPUT: ',
	// 	JSON.stringify(finalUnmappedLetters, null, 5),
	// 	JSON.stringify(finalMapping, null, 5)
	// );
	// look at remaining unmapped letters. these letters didn't map to any sounds, append them to the
	// previous mapping object. if they are at the start of the word, then prepend them at the start
	// of the first mapping object.
	for (const { spelling, index } of finalUnmappedLetters) {
		// find the preceding mapping object
		// then append this letter to it
		const precedingMappingObj = getPrecedingMappingObject(finalMapping, index);

		if (precedingMappingObj) {
			precedingMappingObj.spelling += spelling;
			precedingMappingObj.endLetterIndex += spelling.length;
		} else {
			// if there is no precedingMappingObj, then find the following mapping object and prepend everything before its startIndex
			// to make sure order is maintained properly
			const followingMappingObj = getFollowingMappingObject(finalMapping, index);

			if (followingMappingObj) {
				followingMappingObj.spelling = spelling + followingMappingObj.spelling;
				followingMappingObj.startLetterIndex -= spelling.length;
			}
		}
	}

	// doing this above will map every letter of the word, so we should have nothing in here after
	while (finalUnmappedLetters.length > 0) {
		finalUnmappedLetters.pop();
	}

	// console.log(
	// 	'handleUnmappedLetters RESULT:',
	// 	'finalUnmappedLetters',
	// 	finalUnmappedLetters,
	// 	'finalMapping',
	// 	JSON.stringify(finalMapping, null, 5)
	// );
};
//----------------------------------------------------------------------------------------------------------
// attach remaining unmapped sounds
//----------------------------------------------------------------------------------------------------------

// we need to decode the soundstring back to the original when we are handling any unmapped sounds
const handleRemainingUnmappedSounds = (sounds, mappingArray, finalUnmappedSounds) => {
	// console.log(
	// 	'handleRemainingUnmappedSounds INPUT',
	// 	sounds,
	// 	JSON.stringify(mappingArray, null, 5),
	// 	JSON.stringify(finalUnmappedSounds, null, 5)
	// );
	// console.log('there are ', finalUnmappedSounds.length, 'unmapped sounds');
	const soundIndexesToRemove = [];

	for (const { soundIndex } of finalUnmappedSounds) {
		// either append this sound with the preceding mapping object, or prepend to the following mapping object
		let mappingObject = getPrecedingMappingObjectGivenSoundIndex(mappingArray, soundIndex);
		if (mappingObject) {
			mappingObject.sound += decodeSoundString(sounds[soundIndex]);
			soundIndexesToRemove.push(soundIndex);
		} else {
			// check if there's an object after that we should prepend the sound to!
			// console.log('try to attach sound to following mapping');
			mappingObject = getFollowingMappingObjectGivenSoundIndex(mappingArray, soundIndex);
			if (mappingObject) {
				// console.log('found following mapping object');
				mappingObject.sound = decodeSoundString(sounds[soundIndex]) + mappingObject.sound;
				soundIndexesToRemove.push(soundIndex);
			} else {
				// console.log('there is no following object to attach this sound!');
			}
		}
	}

	for (const soundIndexToRemove of soundIndexesToRemove) {
		const index = finalUnmappedSounds.findIndex((obj) => obj.soundIndex === soundIndexToRemove);
		if (index !== -1) {
			removeElementFromArray(finalUnmappedSounds, index);
		}
	}

	// console.log(
	// 	'handleRemainingUnmappedSounds RESULT:',
	// 	'mappingArray',
	// 	JSON.stringify(mappingArray, null, 5),
	// 	'finalUnmappedSounds',
	// 	finalUnmappedSounds
	// );
};

//----------------------------------------------------------------------------------------------------------
// handle unmapped sounds
//----------------------------------------------------------------------------------------------------------
// will modify finalUnmappedLetters and/or finalUnmappedSounds if they get mapped
const createMappingsForUnmappedSoundsAndLetters = (
	word,
	sounds,
	initialMapping,
	initialUnmappedSounds,
	finalUnmappedLetters,
	finalUnmappedSounds
) => {
	// console.log('createMappingsForUnmappedSoundsAndLetters INPUT', initialMapping, initialUnmappedSounds);

	// look at the preceding sound and following sound. look at the mappings for them. figure
	// out what letters would correspond to the unmapped sound. but only map 1 letter to the sound.
	// there's a step later that will append other unmapped letters.

	let intermediateMapping = [...initialMapping];
	const newMappingObjects = [];
	// go through unmapped sounds
	for (const i in initialUnmappedSounds) {
		const soundIndex = initialUnmappedSounds[i].soundIndex;
		// console.log('= looking at sound', initialUnmappedSounds[i].sound, 'with index', soundIndex);

		// figure out what letters this sound should be covering based on the endIndex+1 of the
		// preceding sound (or 0 if none), and startIndex-1 of next sound (or end of word if none)
		let startLetterIndex = 0;
		let endLetterIndex = 0;
		if (soundIndex === 0) {
			// sound is the first sound
			startLetterIndex = 0;
		} else {
			// const mappingObject = initialMapping.find((item) => item.soundIndex === soundIndex - 1);
			const mappingObject = getPrecedingMappingObjectGivenSoundIndex(intermediateMapping, soundIndex);
			if (mappingObject) {
				startLetterIndex = mappingObject.endLetterIndex + 1;
			}
		}
		if (soundIndex === sounds.length - 1) {
			// sound is the last sound
			endLetterIndex = word.length - 1;
		} else {
			//const mappingObject = initialMapping.find((item) => item.soundIndex === soundIndex + 1);
			let mappingObject = null;
			let curr = soundIndex + 1;
			while (!mappingObject && curr < sounds.length) {
				mappingObject = intermediateMapping.find((item) => item.soundIndex === curr);
				curr++;
			}
			// console.log('the following mapping object is', mappingObject);
			if (mappingObject) {
				endLetterIndex = mappingObject.startLetterIndex - 1;
			} else {
				// there is no mapping object afterwards, so endLetterIndex would be the end of the word
				endLetterIndex = word.length - 1;
			}
		}
		// the letters the sound should be mapped to will be bounded by startLetterIndex and endLetterIndex (inclusive)

		// there are letters to map!
		if (startLetterIndex <= endLetterIndex) {
			// Note: We only map the sound to 1 letter. This is because there could be multiple unmapped sounds in sequence,
			// and so we want the other sounds to map to spelling too. any extra spelling after this step will be mapped later
			// when handling remaining unmapped spelling at the end.
			endLetterIndex = startLetterIndex; // only map the sound to 1 letter

			const spelling = word.substring(startLetterIndex, endLetterIndex + 1); // last param: up to, not including

			// create a mapping object for these letters and push it to newMappingObjects
			const newObject = {
				spelling,
				sound: decodeSoundString(sounds[soundIndex]),
				startLetterIndex,
				endLetterIndex,
				soundIndex // used later to help figure out boundaries of unmapped sounds
			};
			newMappingObjects.push(newObject);

			// push a reference to this temporary intermediate array so that we can account for any
			// new mappings when we look for mapping objects that precede or follow a sound
			// intermediateMapping.push(newObject);
			intermediateMapping = mergeMappingArrays(intermediateMapping, newMappingObjects);

			// remove used letters from finalUnmappedLetters
			let found = true;
			do {
				const index = finalUnmappedLetters.findIndex(
					(obj) => obj.index >= startLetterIndex && obj.index <= endLetterIndex
				);
				// console.log('find letter where ' + startLetterIndex + ',' + endLetterIndex, index);
				found = index !== -1;
				if (found) {
					removeElementFromArray(finalUnmappedLetters, index);
				}
			} while (found);

			// remove the current mapped sound from finalUnmappedSounds
			found = true;
			do {
				const index = finalUnmappedSounds.findIndex((obj) => obj.soundIndex === soundIndex);
				found = index !== -1;
				if (found) {
					removeElementFromArray(finalUnmappedSounds, index);
				}
			} while (found);
		} else {
			// there are no letters to map!
			// console.log('there are no letters to map this sound!', sounds[soundIndex]);
			// do remaining handling later where we just append sound to another mapping object
		}
	}

	// console.log(
	// 	'createMappingsForUnmappedSoundsAndLetters RESULT:',
	// 	'newMappingObjects',
	// 	JSON.stringify(newMappingObjects, null, 5)
	// );
	return newMappingObjects;
};

//----------------------------------------------------------------------------------------------------------
// get the mapping object that immediately precedes a given letterIndex
//----------------------------------------------------------------------------------------------------------
const getPrecedingMappingObject = (finalMapping, letterIndex) => {
	let result = null;
	for (const mapping of finalMapping) {
		if (mapping.endLetterIndex < letterIndex) {
			if (!result || mapping.endLetterIndex > result.endLetterIndex) {
				result = mapping;
			}
		}
	}
	return result;
};

//----------------------------------------------------------------------------------------------------------
// get the mapping object that immediately follows a given letterIndex
//----------------------------------------------------------------------------------------------------------
const getFollowingMappingObject = (finalMapping, letterIndex) => {
	let result = null;
	for (const mapping of finalMapping) {
		if (mapping.startLetterIndex > letterIndex) {
			if (!result || mapping.startLetterIndex < result.startLetterIndex) {
				result = mapping;
			}
		}
	}
	return result;
};

//----------------------------------------------------------------------------------------------------------
// get the mapping object that immediately precedes a given soundIndex
//----------------------------------------------------------------------------------------------------------
const getPrecedingMappingObjectGivenSoundIndex = (mappingArray, soundIndex) => {
	let mappingObject = null;
	let curr = soundIndex - 1;
	while (!mappingObject && curr >= 0) {
		mappingObject = mappingArray.find((item) => item.soundIndex === curr);
		curr--;
	}
	return mappingObject;
};

//----------------------------------------------------------------------------------------------------------
// get the mapping object that immediately follows a given soundIndex
//----------------------------------------------------------------------------------------------------------
const getFollowingMappingObjectGivenSoundIndex = (mappingArray, soundIndex) => {
	let mappingObject = null;
	let curr = soundIndex + 1;
	while (!mappingObject && curr >= 0) {
		mappingObject = mappingArray.find((item) => item.soundIndex === curr);
		curr++;
	}
	return mappingObject;
};

//----------------------------------------------------------------------------------------------------------
// handle special case
//----------------------------------------------------------------------------------------------------------
// the sounds /əl/ are sometimes spelled 'le'. Since the order is reversed, we want to handle this case
// same for /ər/ sometimes spelled 're'. Currently, letterBefore is either l or r. eSound is either ə or ɛ
const handleSpecialCase = (word, sounds, mappingArray, finalUnmappedSounds, letterBefore, eSound) => {
	// console.log(
	// 	'handleSpecialCase INPUT',
	// 	word,
	// 	sounds,
	// 	JSON.stringify(mappingArray, null, 5),
	// 	JSON.stringify(finalUnmappedSounds, null, 5),
	// 	letterBefore
	// );

	// make sure there is an extra unmapped l or r sound
	const indexL = finalUnmappedSounds.findIndex((item) => item.sound === letterBefore);
	if (indexL === -1) {
		// console.log('Does not apply. No unmapped', letterBefore, ' sound');
		return;
	}

	// look for the le/re in the word
	const checkLetters = letterBefore + 'e';
	if (!word.includes(checkLetters)) {
		// console.log('Does not apply. the letter ', letterBefore, 'does appear immediately before the e');
		return;
	}

	for (let i = 0; i < sounds.length; i++) {
		if (sounds[i] === eSound) {
			// see if there is a preceding letterBefore character in the word. check that letterBefore character was appended
			// to the preceding mapping obj.

			const mappingObj = getPrecedingMappingObjectGivenSoundIndex(mappingArray, i);

			// console.log('preceding obj has ' + mappingObj.spelling + ' spelling');
			if (mappingObj.spelling.length <= 1) {
				// we do not have multiple letters in the preceding mapping. go on to look at next ə
				continue;
			} else if (mappingObj.spelling[mappingObj.spelling.length - 1] === letterBefore) {
				// double check that we have a missing l/r sound right after the ə
				const indexL = finalUnmappedSounds.find((item) => item.sound === letterBefore && item.soundIndex == i + 1);
				if (indexL === -1) {
					// not the right conditions, so go on to look at next ə
					continue;
				}
				// remove the letterBefore if there are multiple letters there
				mappingObj.spelling = mappingObj.spelling.slice(0, mappingObj.spelling.length - 1);
				mappingObj.endLetterIndex -= 1;

				// prepend the l/r to the mapping with the ə
				// console.log('SOUND INDEX IS', i, 'AND WE ARE LOOKING AT', sounds[i]);
				const eMappingObj = mappingArray.find((item) => item.soundIndex === i);
				eMappingObj.sound = eMappingObj.sound + letterBefore;
				eMappingObj.spelling = letterBefore + eMappingObj.spelling;
				eMappingObj.startLetterIndex -= 1;

				const eIndex = finalUnmappedSounds.findIndex((obj) => obj.soundIndex === i + 1);
				if (eIndex !== -1) {
					removeElementFromArray(finalUnmappedSounds, eIndex);
				}
			}
		}
	}
	// console.log(
	// 	'handleSpecialCase RESULT:',
	// 	'mappingArray',
	// 	JSON.stringify(mappingArray, null, 5),
	// 	'finalUnmappedSounds',
	// 	finalUnmappedSounds
	// );
};

//----------------------------------------------------------------------------------------------------------
// update a mapping array with the spellings from the original word (this will handle capital letters and hyphens)
//----------------------------------------------------------------------------------------------------------
const restoreUsingRawLetters = (rawWord, mappingArray) => {
	const hyphens = [];

	// update indexes based on where hyphens are in the word
	let hyphenIndex = rawWord.indexOf('-');
	while (hyphenIndex !== -1) {
		hyphens.push(hyphenIndex);
		hyphenIndex = rawWord.indexOf('-', hyphenIndex + 1);
	}

	for (const hIndex of hyphens) {
		for (const map of mappingArray) {
			if (map.startLetterIndex >= hIndex) {
				map.startLetterIndex += 1;
			}
			if (map.endLetterIndex >= hIndex) {
				map.endLetterIndex += 1;
			}
		}
	}

	// use the spelling from original word because some may be capitalized
	for (const map of mappingArray) {
		const originalLetters = rawWord.substring(map.startLetterIndex, map.endLetterIndex + 1);
		map.spelling = originalLetters;
	}
	// console.log('restoreUsingRawLetters RESULT:', 'mappingArray', JSON.stringify(mappingArray, null, 5));
};

//----------------------------------------------------------------------------------------------------------
// remove element from array
//----------------------------------------------------------------------------------------------------------
const removeElementFromArray = (array, index) => {
	if (index < 0 || index >= array.length) {
		return;
	}
	for (let i = index; i < array.length; i++) {
		if (i !== array.length - 1) {
			array[i] = array[i + 1];
		}
	}
	array.pop();
};

//----------------------------------------------------------------------------------------------------------
// merge two mapping arrays
//----------------------------------------------------------------------------------------------------------
// two pointers as indexes into: newMappingObjects and initialMapping
// look at startIndexes, copy mapping object of whichever appears first and push to finalMapping
// be sure that both pointers finish iterating through their respective arrays
const mergeMappingArrays = (initialMapping, newMappingObjects) => {
	const finalMapping = [];
	let index1 = 0;
	let index2 = 0;

	while (index1 < initialMapping.length && index2 < newMappingObjects.length) {
		if (initialMapping[index1].startLetterIndex < newMappingObjects[index2].startLetterIndex) {
			finalMapping.push({ ...initialMapping[index1] });
			index1++;
		} else {
			finalMapping.push({ ...newMappingObjects[index2] });
			index2++;
		}
	}

	// loop through add any remaining from initialMapping
	while (index1 < initialMapping.length) {
		finalMapping.push({ ...initialMapping[index1] });
		index1++;
	}
	// loop through add any remaining from newMappingObjects
	while (index2 < newMappingObjects.length) {
		finalMapping.push({ ...newMappingObjects[index2] });
		index2++;
	}

	return finalMapping;
};

//----------------------------------------------------------------------------------------------------------
// replace soundstrings
//----------------------------------------------------------------------------------------------------------
const processSoundStrings = (sounds) => {
	// console.log('ipaToSoundStringMap', ipaToSoundStringMap);
	let singleCharSounds = sounds;
	for (const s of Object.keys(ipaToSoundStringMap)) {
		singleCharSounds = singleCharSounds.replaceAll(s, ipaToSoundStringMap[s]);
	}

	// use the converted sounds to get the syllables
	const convertedSounds = singleCharSounds.split('-').join('').split(''); // just get an array of all the sounds
	// console.log('processSoundStrings RESULT:', convertedSounds);
	return convertedSounds;
};

//----------------------------------------------------------------------------------------------------------
// given a soundstring, get it's IPA
//----------------------------------------------------------------------------------------------------------
const decodeSoundString = (soundString) => {
	if (soundString in soundStringToIpaMap) {
		return soundStringToIpaMap[soundString];
	}
	return soundString;
};

export { getOrthographicMapping };

//----------------------------------------------------------------------------------------------------------
// testing
//----------------------------------------------------------------------------------------------------------
/*let runTests = async () => {

	// getOrthographicMapping('mop', 'mɑp');
	// getOrthographicMapping('blocks', 'blɑks');
	// getOrthographicMapping('mop', 'mɑp');
	// getOrthographicMapping('blocks', 'blɑks');
	// getOrthographicMapping('toast', 'toʊst');
	// getOrthographicMapping('nachos', 'nɑ-tʃoʊz');
	// getOrthographicMapping('cotton', 'kɑ-tən');
	// getOrthographicMapping('crackers', 'kræ-kərz');
	// getOrthographicMapping('hospital', 'hɑ-spɪ-təl');
	// getOrthographicMapping('refrigerator', 'rə-frɪ-dʒər-eɪ-tər');
	// getOrthographicMapping('beard', 'bird');
	// getOrthographicMapping('octopus', 'ɑk-tə-pəs');
	// getOrthographicMapping('knee', 'ni');
	// getOrthographicMapping('dissatisfy', 'dɪ-sæ-tɪ-sfaɪ');

	//---CASE1: ALL SOUNDS ACCOUNTED FOR, BUT UNMAPPED LETTER (SHOULD GROUP IT WITH PREVIOUS IF POSSIBLE -- ALSO KEEP IT IN SAME SYLLABLE... doable?)

	// unmapped letter au (unmapped letter, no missing sound, should group it with previous)
	// getOrthographicMapping('restaurant', 'rɛ-strɑnt');

	// unmapped letter e (unmapped letter, no missing sound, just group it with previous)
	// getOrthographicMapping('house', 'haʊs');

	// unmapped letters ugh
	// getOrthographicMapping('though', 'θoʊ');

	// unmapped letter l
	// getOrthographicMapping('balm', 'bɑm');

	// unmapped letter o (should group it with previous?)
	// getOrthographicMapping('graduation', 'ɡræ-dʒu-eɪ-ʃən');

	// ---CASE2: UNMAPPED LETTER, UNMAPPED SOUND IN SAME PLACE, SHOULD ASSIGN THEM

	// unmapped letters oe, unmapped sound u
	// getOrthographicMapping('canoe', 'kə-nu');

	// unmatched u letter should map to w sound
	// getOrthographicMapping('queen', 'kwin');

	//---CASE3: ALL LETTERS ACCOUNTED FOR, BUT UNMAPPED SOUND. SHOULD GROUP SOUND WITH PREVIOUS? CHECK WITH MEGAN?

	// the sound s doesn't have any letters to map to, so it should group with the k sound
	// getOrthographicMapping('fox', 'fɑks');

	// EXTRA TESTS

	// getOrthographicMapping('actor', 'æk-tər');
	// getOrthographicMapping('bird', 'bɝrd');
	// getOrthographicMapping('airplane', 'ɛr-pleɪn');
	// getOrthographicMapping('excel', 'ɪk-sɛl');
	// getOrthographicMapping('game', 'ɡeɪm');
	// getOrthographicMapping('asparagus', 'ə-spæ-rə-ɡəs');
	// getOrthographicMapping('dough', 'doʊ');
	// getOrthographicMapping('bicycle', 'baɪ-sə-kəl');
	// getOrthographicMapping('cabbage', 'kæ-bɪdʒ');
	// getOrthographicMapping('cylinder', 'sɪ-lən-dər'); // 2
	// getOrthographicMapping('calculator', 'kæl-kjə-leɪ-tər');
	// getOrthographicMapping('daughter', 'dɔ-tər'); // 3
	// getOrthographicMapping('doughnut', 'doʊ-nʌt');
	// getOrthographicMapping('finger', 'fɪŋ-ɡər');
	// getOrthographicMapping('computer', 'kəm-pju-tər');
	// getOrthographicMapping('cube', 'kjub');
	// getOrthographicMapping('chocolate', 'tʃɑ-klət');
	// getOrthographicMapping('blood', 'blʌd');
	// getOrthographicMapping('honour', 'hon-nər');
	// getOrthographicMapping('honor', 'hon-nər');
	//getOrthographicMapping('box', 'bɑks');
	//getOrthographicMapping('candlestick', 'kændl-stɪk');
	// getOrthographicMapping('fire', 'faɪ-ər');
	// getOrthographicMapping('x-ray', 'ɛks-reɪ');
	// getOrthographicMapping('TV', 'ti-vi');
	//getOrthographicMapping('Christmas', 'krɪ-sməs');
	// getOrthographicMapping('vegetable', 'vɛ-dʒə-tə-bəl');
	// getOrthographicMapping('choir', 'kwaɪ-ər');
	// getOrthographicMapping('mozzarella', 'mɑt-sə-rɛ-lə');
	// getOrthographicMapping('squirrel', 'skwɝr-əl');

	// getOrthographicMapping('bottlecap', 'bɑ-təl-kæp');
};*/
