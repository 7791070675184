import mixpanel from 'mixpanel-browser';
import env from './env';

export const MIXPANEL_LANDING_PAGE_DEVICE_ID_KEY = 'MIXPANEL_LANDING_PAGE_DEVICE_ID_KEY';
export const MIXPANEL_PICKED_ROLE_CLINICIAN_DEVICE_ID_KEY = 'MIXPANEL_PICKED_ROLE_CLINICIAN_DEVICE_ID_KEY';
export const MIXPANEL_PAGE_VIEW_CREATE_PASSWORD_DEVICE_ID_KEY = 'MIXPANEL_PAGE_VIEW_CREATE_PASSWORD_DEVICE_ID_KEY';

let didLoad = false;

export const init = () => {
	const mixpanelProjectToken = env('MIXPANEL_PROJECT_TOKEN');
	if (mixpanelProjectToken) {
		try {
			mixpanel.init(mixpanelProjectToken, {
				track_pageview: true,
				// recommended config option for client tracking
				// https://docs.mixpanel.com/docs/quickstart/connect-your-data?sdk=swift#what-are-the-recommended-configuration-options
				persistence: 'localStorage',
				loaded: () => {
					didLoad = true;
				}
			});
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
		} catch (error) {
			console.error('Mixpanel not loaded on client when it should be');
		}
	} else {
		console.warn(
			`Missing env variable: 'MIXPANEL_PROJECT_TOKEN'. \nOkay to proceed but there won't be any analytics tracked.`
		);
	}
};

export const authenticatedUser = ifLoadedCallSafely((user) => {
	// Initially, the 'identify' event was only called to link anonymous events (before the user had created an account) to an
	// authenticated user after creating their account. This was for the 'Signup Flow' board in Mixpanel.
	//
	// After adding more frontend mixpanel events ('Resource View', 'Resource Add To Patient', 'Start Tool') we noticed that
	// those events were also anonymous and not properly connected to the mixpanel user (created from the backend mixpanel events).
	//
	// Now, we always call 'identify' with the current authenticated user's id, so that all their events get linked with the same
	// mixpanel user on the mixpanel website.
	// This way all their events will have all the correct mixpanel user properties (role, Is Tactus Employee, etc) and
	// will make our mixpanel analytics more accurate.
	mixpanel.track('identify', {
		$user_id: user.id
	});
	// in case either of the 'Signup - Picked Role - Clinician' event or 'Signup - Page View - Create Password' events
	// were tracked anonymously prior to authentication, make sure those events are merged with the authenticated user
	mergeAuthenticatedUserEventsWithAnonymousEvents(user, MIXPANEL_PICKED_ROLE_CLINICIAN_DEVICE_ID_KEY);
	mergeAuthenticatedUserEventsWithAnonymousEvents(user, MIXPANEL_PAGE_VIEW_CREATE_PASSWORD_DEVICE_ID_KEY);

	// merge anonymously tracked events from landing page (which all happen outside the platform) with the authenticated user
	mergeAuthenticatedUserEventsWithAnonymousEvents(user, MIXPANEL_LANDING_PAGE_DEVICE_ID_KEY);
});

const mergeAuthenticatedUserEventsWithAnonymousEvents = (user, deviceIdKey) => {
	const $device_id = localStorage.getItem(deviceIdKey);
	// if there is a device id defined in local storage, use it as a prop for the identify event
	if ($device_id) {
		// https://docs.mixpanel.com/docs/tracking-methods/id-management/identifying-users
		// https://docs.mixpanel.com/docs/tracking-methods/id-management/migrating-to-simplified-id-merge-system
		// by tracking this event with both a $device_id and $user_id, mixpanel will merge any anonymous events
		// that were tracked with $device_id with events tracked with the identified user.id
		//
		// also, using any event name with these props will still do the merge, it doesn't necessarily need to be the 'identify' event
		mixpanel.track('identify', {
			$device_id,
			$user_id: user.id
		});
		// after using the device id as a prop in the tracking, remove it from local storage
		localStorage.removeItem(deviceIdKey);
	}
};

export const trackSignupFlowPickedRole = ifLoadedCallSafely((role) => {
	storeMixpanelDeviceIdToLocalStorageWithKey(MIXPANEL_PICKED_ROLE_CLINICIAN_DEVICE_ID_KEY);
	mixpanel.track('Signup Flow', { step: 'Picked Role', role });
});

export const trackSignupFlowPageViewCreatePassword = ifLoadedCallSafely(() => {
	storeMixpanelDeviceIdToLocalStorageWithKey(MIXPANEL_PAGE_VIEW_CREATE_PASSWORD_DEVICE_ID_KEY);
	mixpanel.track('Signup Flow', { step: 'Page View', name: 'Create Password' });
});

const storeMixpanelDeviceIdToLocalStorageWithKey = (key) => {
	const device_id = mixpanel?.cookie?.props?.$device_id;
	if (device_id) {
		localStorage.setItem(key, device_id);
	}
};

export const trackCancelSubscriptionFeedback = ifLoadedCallSafely((userId, reasons, message) => {
	mixpanel.track('Cancel Subscription Data', {
		distinct_id: userId,
		reasons,
		message
	});
});

export const trackResourceView = ifLoadedCallSafely((userId, resource, location) => {
	mixpanel.track('Resource View', {
		distinct_id: userId,
		resource: resource.displayName,
		focus: resource.focus,
		type: resource.resourceType,
		location
	});
});

export const trackResourceDownload = ifLoadedCallSafely((userId, resource, location) => {
	mixpanel.track('Resource Download', {
		distinct_id: userId,
		resource: resource.displayName,
		focus: resource.focus,
		type: resource.resourceType,
		location
	});
});

export const trackResourceAddToPatient = ifLoadedCallSafely((userId, resource, location) => {
	mixpanel.track('Resource Add To Patient', {
		distinct_id: userId,
		resource: resource.displayName,
		focus: resource.focus,
		type: resource.resourceType,
		location
	});
});

/**
 * Can be called whenever user has started using a Tool.
 * If the tool has categories and requires the user to pick a category before actually starting to use the tool, this function should be called after the category has been picked.
 * @param {string} toolAndCategory - Can be either just the tool name (if the tool doesn't have categories) or the tool name with the name of chosen category.
 */
export const trackToolUsage = ifLoadedCallSafely((userId, toolAndCategory) => {
	mixpanel.track('Start Tool', {
		distinct_id: userId,
		toolAndCategory
	});
});

export const reset = ifLoadedCallSafely(() => {
	mixpanel.reset();
});

function ifLoadedCallSafely(func) {
	return (...args) => {
		try {
			if (didLoad) {
				return func(...args);
			}
		} catch (error) {
			console.error(error);
		}
	};
}
